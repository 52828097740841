import React from 'react'
import styles from '../style'
import { logo, rurnor, address, phone, email } from '../assets'
import { navLinks, socialMedia } from '../constants'

const Footer = () => {
  return (
    <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
      <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
        <div className='flex-1 flex flex-col justify-start mr-10'>
          <img
            src={rurnor}
            alt='logo'
            className='w-[266px] h-[172px] object-contain'
          />
          <p className={`${styles.paragraph} mt-4 max-w-[310px]`}>
            Empowering tech innovation with tailored projects and transformative internships at Rurnor Tech
          </p>
        </div>
        <div className='flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10'>

          <div className='flex flex-col ss:my-0 my-4 min-w-[150px]'>
            <h4 className='font-poppins font-medium text-[18px] leading-[27px] text-white'>
              Links
            </h4>
            <ul className='list-none mt-4 space-y-4'>
              {navLinks.map((item, index) => (
                <li
                  key={item.id}
                  className={`font-poppins font-medium text-[16px] leading-[24px] text-dimWhite hover:text-blue-600 cursor-pointer `}
                >
                  <a href={`#${item.id}`}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>

          </div>
          <div className="flex flex-col  font-poppins">
            <span className=' text-4xl text-gradient font-bold'>Connect With Us</span>
            <div className="space-y-4 mt-4">
              <div className="flex items-center space-x-2  mt-2">
                <img src={email} alt="Email" className="w-6 h-6" />
                <span className='text-dimWhite'>rurnortech@gmail.com</span>
              </div>
              <div className="flex items-center space-x-2 mt-2 flex-wrap">
                <img src={phone} alt="Phone" className="w-6 h-6" />
                <span className='text-dimWhite'>+91 8072098022,</span>
                <span className='text-dimWhite'>+91 9840990958,</span>
                <span className='text-dimWhite'> +91 7338849849 </span>
              </div>
              <div className="flex items-center space-x-2 mt-2">
                <img src={address} alt="Address" className="w-6 h-6" />
                <span className='text-dimWhite'>209/1 Choolaimedu Lane,Choolaimedu HighRoad, Chennai-600094</span>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className='w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]'>
        <p className='font-poppins font-normal  text-center text-[18px] leading-[27px] text-white'>
          2024 Rurnor Tech. All Rights Reserved.
        </p>
        <div className='flex flex-row md:mt-0 mt-6'>
          {socialMedia.map((social) => (
            <a
              href={social.link}
              key={social.id}
              target="_blank"
              rel="noopener noreferrer"
              className={`w-[21px] h-[21px] object-contain cursor-pointer mr-6`}
            >
              <img
                src={social.icon}
                alt={social.id}
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Footer
