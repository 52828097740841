import React from 'react'
import { features } from '../constants'
import styles, { layout } from '../style'
import Button from './Button'

const FeatureCard = ({ icon, title, content, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px] w-full {index !== features.length - 1 ? 'mb-6' : 'mb-0'} feature-card`}>
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-[#15318c]`}>
      <img
        src={icon}
        alt='icon'
        className='w-[50%] h-[50%] object-contain'
      />
    </div>
    <div className='flex-1 flex flex-col ml-3'>
      <h4 className='font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1'>
        {title}
      </h4>
      <p className='font-poppins font-medium text-dimWhite text-[16px] leading-[24px]'>
        {content}
      </p>
    </div>
  </div>
)

const Business = () => {
  return (
    <section id='about' className={` gap-8 ${layout.section}`}>
      <div className={`${layout.sectionImg} flex-col  sm:mb-10 `}>
        {features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index}/>
        ))}
      </div>
     
      <div className={layout.sectionInfo}>
        
        <h2 className={`font-poppins font-semibold xs:text-[48px] mt-10 lg:mt-10 text-[48px] text-white  w-full leading-[48px]`}>Elevate Your Academic Journey <br className='sm:block hidden'/>with Our Expertise.</h2>
        <p className={`${styles.paragraph} max-w-[500px] mt-5`}>
          Our services provide students with the resources and support needed to excel in their final year projects and internships. We ensure that you gain the necessary skills and experience to succeed in your career.
        </p>
        {/* <div className='absolute z-[3] -left-1/2 top-100 w-[50%] h-[50%] rounded-full white__gradient'/>
        <div className='absolute z-[0] -left-1/2 bottom-0 w-[50%] h-[50%] rounded-full pink__gradient'/>
        <div className='absolute z-[3] -right-1/2 top-100 w-[50%] h-[50%] rounded-full white__gradient'/>
        <div className='absolute z-[0] -right-1/2 bottom-0 w-[50%] h-[50%] rounded-full pink__gradient'/> */}
        <Button styles='mt-10'/>

      </div>
    </section>
  )
}

export default Business
