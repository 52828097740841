import React, { useState } from 'react';
import { close, menu, rurnor } from '../assets';
import { navLinks } from '../constants';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      // Update URL without reloading the page
      window.history.pushState(null, null, `/${id}`);
    }
  };

  return (
    <nav className='w-full flex py-4 justify-between items-center navbar'>
      <img src={rurnor} alt='rurnor' className='w-[174px] h-[54px]' />
      <ul className='list-none sm:flex hidden justify-end items-center flex-1'>
        {navLinks.map((nav, i) => (
          <li 
            key={nav.id}
            className={`font-poppins font-medium cursor-pointer text-[17px] ${i === navLinks.length - 1 ? 'mr-0' : 'mr-10'} ${nav.id === 'contactus' ? 'p-2 px-6 rounded-full font-semibold bg-gradient-to-r from-white via-gray-300 to-[#ffffff] text-black' : 'text-white hover:text-blue-700'}`}
            onClick={() => scrollToSection(nav.id)}
          >
            {nav.title}
          </li>        
        ))}
      </ul>
      <div className='sm:hidden flex flex-1 justify-end items-center'>
        <img
          src={toggle ? close : menu}
          alt='menu'
          className='w-[28px] h-[28px] object-contain'
          onClick={() => setToggle(prev => !prev)}
        />
        <div className={`${toggle ? 'flex' : 'hidden'} p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[300px] rounded-xl sidebar`}>
          <ul className='list-none flex flex-col ml-7 justify-center items-center flex-1'>
            {navLinks.map((nav, i) => (
              <li 
                key={nav.id}
                className={`font-poppins font-medium cursor-pointer text-[20px] ${i === navLinks.length - 1 ? 'mr-0' : 'mb-7'} text-white mr-10 hover:text-blue-700`}
                onClick={() => scrollToSection(nav.id)}
              >
                {nav.title}
              </li>        
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
