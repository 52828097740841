import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import styles, { layout } from '../style';
import { card } from '../assets';

const CardDeal = () => {
  const swiperRef = useRef(null);

  const updateSlidesPerView = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      if (window.innerWidth < 501) {
        swiper.params.slidesPerView = 1;
      } else if (window.innerWidth < 724) {
        swiper.params.slidesPerView = 2;
      } else {
        swiper.params.slidesPerView = 2.3;
      }
      swiper.update();
    }
  };

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener('resize', updateSlidesPerView);
    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

  const internships = [
    {
      id: "internship-1",
      title: "Web Development Intern",
      description: "Join our web development team and gain hands-on experience in creating responsive and dynamic websites using the latest web technologies such as HTML5, CSS3, and JavaScript frameworks like React and Vue. You'll work on designing user interfaces, implementing front-end functionalities, and optimizing website performance for various devices and browsers.",
      avatar: "path/to/web-development-image.jpg"
    },
    {
      id: "internship-2",
      title: "App Development Intern",
      description: "As an app development intern, you'll contribute to the creation of mobile applications for both Android and iOS platforms. You'll work closely with our senior developers to design user-friendly interfaces, implement app functionalities using Swift, Kotlin, or React Native, and conduct thorough testing to ensure app stability and performance across different devices.",
      avatar: "path/to/app-development-image.jpg"
    },
    {
      id: "internship-3",
      title: "AI Intern",
      description: "Explore the world of Artificial Intelligence (AI) as an intern with us. You'll be involved in developing AI-powered solutions that automate tasks, analyze large datasets, and provide valuable insights. Gain experience in machine learning algorithms, neural networks, natural language processing (NLP), and computer vision applications.",
      avatar: "path/to/ai-image.jpg"
    },
    {
      id: "internship-4",
      title: "ML Intern",
      description: "As a machine learning (ML) intern, you'll have the opportunity to work on projects that involve building and training machine learning models to solve complex data problems. Collaborate with our data science team to preprocess data, select appropriate algorithms, and evaluate model performance using tools like TensorFlow or PyTorch.",
      avatar: "path/to/ml-image.jpg"
    },
    {
      id: "internship-5",
      title: "React JS Intern",
      description: "Assist in developing interactive user interfaces (UIs) and single-page applications (SPAs) using React JS. Gain hands-on experience in building reusable components, managing state with Redux or Context API, and integrating with backend APIs to fetch and display data dynamically. Learn best practices in front-end development and UI/UX design.",
      avatar: "path/to/react-js-image.jpg"
    },
    {
      id: "internship-6",
      title: "Python Intern",
      description: "Join our Python development team and contribute to projects involving web development, data analysis, and automation. Learn to create efficient applications using Python frameworks like Django or Flask, and gain experience in writing clean, scalable code that meets industry standards. Work on backend development, API integrations, and database management.",
      avatar: "path/to/python-image.jpg"
    },

    {
      id: "internship-8",
      title: "Data Science Intern",
      description: "Assist in extracting actionable insights from large datasets using advanced analytical methods and machine learning techniques. Gain hands-on experience in data preprocessing, exploratory data analysis (EDA), predictive modeling, and data visualization using tools like Python, R, and libraries such as Pandas, NumPy, and Matplotlib. Collaborate with our data scientists on real-world projects and contribute to decision-making processes based on data-driven insights.",
      avatar: "path/to/data-science-image.jpg"
    },
  ];
  

  return (
    <section id="internships" className={layout.section}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>Explore Our Internship Opportunities</h2>
        <p className={`${styles.paragraph} `}>
          We offer a variety of internship opportunities to help you gain practical experience and develop your skills in the tech industry.
        </p>
        <div id="carouselContainer" className="swiper-container mt-10 flex justify-center">
      <Swiper
          ref={swiperRef}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          spaceBetween={30}
          effect="coverflow"
          modules={[EffectCoverflow, Pagination, Autoplay]}
          // coverflowEffect={{
          //   rotate: 0,
          //   depth: 800,
          //   slideShadows: true,
          // }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          autoplay={{ delay: 1000 }}
        >
          {internships.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="ImgHolder">
                <img src={testimonial.avatar} alt={testimonial.title} />
              </div>
              <div className="ContentHolder rounded-b-xl bg-gradient-to-r from-white via-gray-300 to-[#ffffff]">
                <h3 className='font-poppins'>{testimonial.title}</h3>
                <p className='font-poppins text-center'>{testimonial.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
      </div>
    
    </section>
  );
}

export default CardDeal;
