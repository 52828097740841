import React from 'react'
import styles, { layout } from '../style'
import { MdAppShortcut, MdWeb } from 'react-icons/md';
import { FaPython } from "react-icons/fa6";
import { GiArtificialIntelligence } from "react-icons/gi";
import { RiReactjsLine } from "react-icons/ri";
import { BsRobot } from "react-icons/bs";
import { FaChartBar } from "react-icons/fa";
import { FaPhp } from "react-icons/fa6";
import { FaJava } from "react-icons/fa6";

const projects = [
  {
    id: "project-1",
    title: "Web Development",
    icon: <MdWeb size={40}/>,
    description: "Creating responsive and dynamic websites using modern web technologies.",
  },
  {
    id: "project-2",
    title: "App Development",
    icon: <MdAppShortcut size={40}/>,
    description: "Developing mobile applications for both Android and iOS platforms.",
  },
  {
    id: "project-3",
    title: "Artificial Intelligence",
    icon: <GiArtificialIntelligence size={40}/>,
    description: "Implementing AI solutions to automate tasks and provide intelligent insights.",
  },
  {
    id: "project-4",
    title: "Machine Learning",
    icon: <BsRobot size={40}/>,
    description: "Building machine learning models to solve complex data problems.",
  },
  {
    id: "project-5",
    title: "React JS",
    icon: <RiReactjsLine size={40}/>,
    description: "Developing interactive UIs and single-page applications with React JS.",
  },
  {
    id: "project-6",
    title: "Java",
    icon: <FaJava size={40}/>,
    description: "Developing versatile and powerful applications with Java's robust ecosystem.",
},
  {
    id: "project-7",
    title: "Python",
    icon: <FaPython size={40}/>,
    description: "Developing versatile and efficient applications using Python for various needs.",
  },
  {
    id: "project-8",
    title: "PHP",
    icon: <FaPhp size={40}/>,
    description: "Mastering PHP for dynamic web development and robust backend solutions.",
  },
  {
    id: "project-9",
    title: "Data Analytics",
    icon: <FaChartBar size={40}/>,
    description: "Extracting insights and knowledge from data using advanced analytical methods.",
  },
];

const ProjectCard = ({ title, description,icon }) => (
  <div className="bg-white bg-opacity-10 rounded-lg shadow-xl h-[220px] p-5  feature-card backdrop-filter backdrop-blur-md">
    <div className="text-left mb-2">{icon}</div>
    <h3 className="text-white text-xl font-semibold mb-2">{title}</h3>
    <p className="text-dimWhite pb-5">{description}</p>
  </div>
)

const Billing = () => {
  return (
    <section id='projects' className={`${layout.section} `}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>Our Expertise</h2>
        <p className={`${styles.paragraph}`}>
          We provide a wide range of services to help students excel in their projects. Our expertise covers various fields, ensuring you have the support needed to succeed.
        </p>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 ${styles.paragraph}`}>
        <div className='absolute left-2/4 w-[50%] h-[50%] rounded-full white__gradient'/>
        <div className='absolute left-1/2 w-[50%] h-[50%] rounded-full pink__gradient'/>
        {projects.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </div>
      </div>
    
    </section>
  )
}

export default Billing
